import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Colors from '../../../utils/Colors';

const HomeScholarshipLoading = () => {
    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    mt: 8,
                    mb: 5,
                }}
            >
                <Box
                    sx={{
                        width: '250px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '15px',
                        height: '40px',
                        backgroundColor: Colors.PRIMARY_BACKGROUND_LIGHT,
                    }}
                >
                    <Skeleton variant="text" width="50%" height={40} />
                </Box>
                <Box>
                    <Skeleton variant="text" width="50%" height={40} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: { xl: '700px' },
                    }}
                >
                    <Skeleton variant="text" width="100%" height={40} />
                </Box>
                <Box mt={2}>
                    <Grid container>
                        {[1, 2, 3, 4].map((item, index) => (
                            <Grid
                                key={index}
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                            >
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height="400px"
                                />
                            </Grid>
                        ))}
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 5,
                                }}
                            >
                                <Skeleton
                                    variant="rectangular"
                                    width="300px"
                                    height="60px"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default HomeScholarshipLoading;
