import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Colors from '../../utils/Colors';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import { useQuery } from '@tanstack/react-query';
import { getObjects } from '../../api/Api';
import SkeletonLoading from '../../components/SkeletonLoading/SkeletonLoading';
import { MetaTags } from 'react-meta-tags';
import NotFoundData from '../../components/NoteFoundData/NotFoundData';

const Project = () => {
    const { data, isError, isLoading, isSuccess } = useQuery(['project'], () =>
        getObjects('project')
    );
    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <SkeletonLoading />;
    }
    if (isSuccess) {
        return (
            <Box>
                <MetaTags>
                    <title>Afghan cosmos - Project</title>
                    <meta name="description" content="Brows our Project page" />
                </MetaTags>
                <Box
                    sx={{
                        height: '400px',
                        backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: { xl: '50%', lg: '50%', xs: '80%' },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 3,
                        }}
                    >
                        <Typography
                            variant="h4"
                            fontWeight={'bold'}
                            sx={{
                                textAlign: 'center',
                                color: Colors.PRIMARY,
                                fontSize: { xl: '45px', xs: '30px' },
                            }}
                        >
                            Successfully Completed Projects
                        </Typography>
                        <Box>
                            <Typography sx={{ textAlign: 'center' }}>
                                Successfully Completed Projects, honors our
                                team's perseverance and commitment to finishing
                                a range of projects.
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    mt: { xl: 0, lg: 0, md: 0, sm: 2, xs: 2 },
                                }}
                            >
                                These achievements show our dedication to
                                excellence and our capacity for overcoming
                                obstacles to succeed.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Container sx={{ mt: 5 }}>
                    {data.length === 0 ? (
                        <NotFoundData type="project" />
                    ) : (
                        <Grid container rowSpacing={3}>
                            {data.map((item, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <ProjectCard data={item} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Container>
            </Box>
        );
    }
};

export default Project;
