import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    Grid,
    Typography,
} from '@mui/material';
import React from 'react';
import Colors from '../../../utils/Colors';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext';

const Footer = () => {
    const phoneNumber = '+93749999994';
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}images/footer/wall.png`;
    const imageUrl2 = `${process.env.REACT_APP_MEDIA_URL}images/png/house-sketch.png`;
    const footerStyle = {
        backgroundImage: `url(${imageUrl})`,

        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        backgroundColor: Colors.BLACK,
        marginTop: '20px',
    };
    const sendMail = () => {
        window.location.href = 'mailto:info@afghancosmos.com';
    };
    return (
        <Box style={footerStyle}>
            <Container>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            height: '200px',
                            backgroundColor: Colors.PRIMARY,
                            mt: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '10px',

                            backgroundImage: `url(${imageUrl2})`,
                            backgroundPosition: 'top right',
                            backgroundRepeat: 'no-repeat',
                            justifyContent: 'center',
                            gap: 2,
                            p: 3,
                        }}
                    >
                        <Box
                            sx={{
                                width: {
                                    xl: '50%',
                                    lg: '50%',
                                    md: '80%',
                                    sm: '100%',
                                    xs: '100%',
                                },
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '30px',
                                        lg: '30px',
                                        md: '30px',
                                        sm: '25px',
                                        xs: '20px',
                                    },
                                }}
                            >
                                Are You Looking To Expand Your Business
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xl: 'row',
                                    lg: 'row',
                                    md: 'row',
                                    sm: 'row',
                                    xs: 'column',
                                },
                                gap: 2,
                            }}
                        >
                            <Box
                                component={'a'}
                                href={`tel:${phoneNumber}`}
                                sx={{
                                    width: {
                                        xl: '160px',
                                        lg: '160px',
                                        md: '140px',
                                        sm: '120px',
                                        xs: '100px',
                                        textDecoration: 'none',
                                        color: Colors.BLACK,
                                    },
                                    backgroundColor: Colors.WHITE,
                                    borderRadius: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <StayCurrentPortraitIcon />
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xl: '18px',
                                            lg: '18px',
                                            md: '15px',
                                            sm: '10px',
                                            xs: '10px',
                                        },
                                    }}
                                >
                                    +93744416777
                                </Typography>
                            </Box>
                            <Button
                                onClick={sendMail}
                                sx={{
                                    width: {
                                        xl: '160px',
                                        lg: '160px',
                                        md: '140px',
                                        sm: '100px',
                                        xs: '100px',
                                    },
                                    height: {
                                        xl: '40px',
                                        lg: '40px',
                                        md: '35px',
                                        sm: '30px',
                                        xs: '30px',
                                    },
                                    backgroundColor: Colors.WHITE,
                                    color: Colors.BLACK,
                                    borderRadius: '5px',
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <EmailIcon />
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xl: '18px',
                                            lg: '18px',
                                            md: '15px',
                                            sm: '10px',
                                            xs: '10px',
                                        },
                                    }}
                                >
                                    Email
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                    <Box mt={8}>
                        <Grid container spacing={5}>
                            <Grid item xl={3} lg={3}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: Colors.WHITE,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Afghan Cosmos
                                    </Typography>
                                    <Box>
                                        <iframe
                                            style={{
                                                marginheight: 0,
                                                marginwidth: 0,
                                                scrolling: 'no',
                                                frameborder: 0,
                                            }}
                                            width="100%"
                                            height="250"
                                            src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=Hedayat%20Plaza+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                        >
                                            <a href="https://www.gps.ie/">
                                                gps devices
                                            </a>
                                        </iframe>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: Colors.WHITE }}
                                    >
                                        Hedayat Plaza | Shahr-e-Naw, Haji
                                        Yaqoob, Kabul, Afghanistan
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xl={3} lg={3}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: Colors.WHITE,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Main Pages
                                    </Typography>
                                    <Link
                                        to={'/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: Colors.WHITE }}
                                        >
                                            Home
                                        </Typography>
                                    </Link>
                                    <Link
                                        to={'/about/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: Colors.WHITE }}
                                        >
                                            About
                                        </Typography>
                                    </Link>
                                    <Link
                                        to={'/service/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: Colors.WHITE }}
                                        >
                                            Services
                                        </Typography>
                                    </Link>
                                    <Link
                                        to={'/blog/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: Colors.WHITE }}
                                        >
                                            Our Blog
                                        </Typography>
                                    </Link>
                                    <Link
                                        to={'/event/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: Colors.WHITE }}
                                        >
                                            Event
                                        </Typography>
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item xl={3} lg={3}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: Colors.WHITE,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Utility pages
                                    </Typography>
                                    <Link
                                        to={'/project/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: Colors.WHITE }}
                                        >
                                            Projects
                                        </Typography>
                                    </Link>
                                    <Link
                                        to={'/scholarship/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: Colors.WHITE }}
                                        >
                                            Scholarship
                                        </Typography>
                                    </Link>
                                    <Link
                                        to={'/job/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: Colors.WHITE }}
                                        >
                                            Jobs
                                        </Typography>
                                    </Link>
                                    <Link
                                        to={'/our-team/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: Colors.WHITE }}
                                        >
                                            Our team
                                        </Typography>
                                    </Link>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: Colors.WHITE }}
                                    >
                                        Term & Condition
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xl={3} lg={3}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: Colors.WHITE,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Contact With Us
                                    </Typography>
                                    <Box
                                        component={'a'}
                                        href="https://www.instagram.com/afghan_cosmos_group?igsh=MW8yamI0cXNyb21jYQ=="
                                        target="_blank"
                                        sx={{
                                            color: Colors.WHITE,
                                            display: 'flex',
                                            gap: 1,
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <InstagramIcon />
                                        <Typography>Instagram</Typography>
                                    </Box>
                                    <Box
                                        component={'a'}
                                        href="https://www.facebook.com/AfghanCosmosGroup?mibextid=kFxxJD"
                                        target="_blank"
                                        sx={{
                                            color: Colors.WHITE,
                                            display: 'flex',
                                            gap: 1,
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <FacebookIcon />
                                        <Typography>Facebook</Typography>
                                    </Box>
                                    <Box
                                        component={'a'}
                                        href="https://www.linkedin.com/company/afghan-cosmos-group/"
                                        target="_blank"
                                        sx={{
                                            color: Colors.WHITE,
                                            display: 'flex',
                                            gap: 1,
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <LinkedInIcon />
                                        <Typography>LinkedIn</Typography>
                                    </Box>
                                    <Box
                                        component={'a'}
                                        href="https://x.com/AfghanCosmos?t=RJYwppQl7EcJRUXzAwHXzA&s=09"
                                        target="_blank"
                                        sx={{
                                            color: Colors.WHITE,
                                            display: 'flex',
                                            gap: 1,
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <XIcon />
                                        <Typography>Twitter</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ borderBottom: '1px solid Gray', mt: 10 }} />
                    <Box sx={{ mt: 2 }}>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            sx={{ color: Colors.WHITE }}
                        >
                            <Typography>Privacy</Typography>
                            <Typography>Term & Condition</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box
                        mt={2}
                        mb={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Typography sx={{ color: Colors.WHITE }}>
                            © {new Date().getFullYear()} Afghan Cosmos - All
                            Rights Reserved
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
