import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import TestimonialUserCard from '../../../components/TestimonialUserCard/TestimonialUserCard';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Colors from '../../../utils/Colors';

const HomeTestimonialLoading = () => {
    return (
        <Box
            sx={{
                minHeight: '900px',
                borderTop: '0.5px solid lightGrey',
                backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                mt: 8,
            }}
        >
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3,
                        pt: 5,
                    }}
                >
                    <Skeleton
                        variant="rectangular"
                        width={200}
                        height={30}
                        sx={{
                            borderRadius: 20,
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Skeleton
                            variant="text"
                            width={300}
                            height={40}
                            sx={{
                                borderRadius: 20,
                            }}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={700}
                            height={80}
                        />
                        <Skeleton variant="text" width={200} height={30} />
                        <Skeleton variant="text" width={200} height={30} />
                        <Grid container spacing={2}>
                            {[1, 2, 3, 4, 5, 6].map((index) => (
                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    key={index}
                                >
                                    <TestimonialUserCard />
                                </Grid>
                            ))}
                        </Grid>
                        <Link
                            to={'/contact/'}
                            style={{ textDecoration: 'none' }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width={180}
                                height={60}
                                sx={{ borderRadius: '15px' }}
                            />
                        </Link>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default HomeTestimonialLoading;
