import React, { useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    TextField,
    Typography,
} from '@mui/material';
import Colors from '../../../utils/Colors';
import { useMutation } from '@tanstack/react-query';
import { addObject } from '../../../api/Api';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

const JoinUs = () => {
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [fullNameError, setFullNameError] = useState();
    const [emailError, setEmailError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        let isValid = true;

        // Validation for Full Name
        if (!fullName || fullName.trim() === '') {
            setFullNameError('Full Name cannot be empty');
            isValid = false;
        } else {
            setFullNameError();
        }

        // Validation for Email
        if (!email || email.trim() === '') {
            setEmailError('Email cannot be empty');
            isValid = false;
        } else if (!validateEmail(email)) {
            setEmailError('Invalid email address');
            isValid = false;
        } else {
            setEmailError();
        }

        if (isValid) {
            // Proceed with form submission if all fields are valid
            let formData = new FormData();
            formData.append('fullName', fullName);
            formData.append('email', email);

            // send data to api
            addJoinUs.mutate(formData);
        }
    };
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    // send data to api using react query
    const addJoinUs = useMutation((data) => addObject('join-us', data), {
        onSuccess: () => {
            enqueueSnackbar('Successfully sent!', { variant: 'success' });
        },
        onError: (data) => {
            enqueueSnackbar(`${data.response.data.email}`, {
                variant: 'error',
            });
        },
    });
    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                }}
            >
                <Box
                    sx={{
                        minHeight: '400px',
                        width: '100%',
                        backgroundColor: Colors.PRIMARY,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Container>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <Typography
                                variant="h3"
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '30px',
                                        lg: '30px',
                                        md: '25px',
                                        sm: '20px',
                                        xs: '20px',
                                    },
                                }}
                            >
                                Want to Join Us?
                            </Typography>
                            <Typography
                                sx={{
                                    color: Colors.WHITE,
                                    textAlign: 'center',
                                    width: { xl: '700px' },
                                    fontSize: {
                                        xl: '20px',
                                        lg: '20px',
                                        md: '15px',
                                        sm: '13px',
                                        xs: '13px',
                                    },
                                    pt: 2,
                                }}
                            >
                                Join us to hear more about available
                                opportunities
                            </Typography>
                            <Box
                                component={'form'}
                                onSubmit={handleSubmit}
                                sx={{
                                    display: 'flex',
                                    flexDirection: {
                                        xl: 'row',
                                        lg: 'row',
                                        md: 'row',
                                        sm: 'column',
                                        xs: 'column',
                                    },
                                    gap: 2,
                                    pt: 3,
                                }}
                            >
                                <TextField
                                    label="Full Name"
                                    variant="outlined"
                                    name="fullName"
                                    error={!!fullNameError}
                                    helperText={fullNameError}
                                    onChange={(e) =>
                                        setFullName(e.target.value)
                                    }
                                    InputProps={{
                                        style: {
                                            color: Colors.WHITE,
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: Colors.WHITE,
                                        },
                                    }}
                                    sx={{
                                        width: { xs: '300px' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: Colors.WHITE,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: Colors.WHITE,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: Colors.WHITE,
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    name="email"
                                    error={!!emailError}
                                    helperText={emailError}
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        style: {
                                            color: Colors.WHITE,
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: Colors.WHITE,
                                        },
                                    }}
                                    sx={{
                                        width: { xs: '300px' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: Colors.WHITE,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: Colors.WHITE,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: Colors.WHITE,
                                            },
                                        },
                                    }}
                                />
                                {addJoinUs.isLoading ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            mt: 1,
                                        }}
                                    >
                                        <CircularProgress
                                            sx={{ color: Colors.WHITE }}
                                        />
                                    </Box>
                                ) : (
                                    <Button
                                        type="submit"
                                        sx={{
                                            backgroundColor: Colors.WHITE,
                                            color: Colors.BLACK,
                                            fontSize: {
                                                xl: '15px',
                                                lg: '15px',
                                                md: '12px',
                                                sm: '8px',
                                                xs: '8px',
                                            },
                                            height: {
                                                xl: '55px',
                                                lg: '55px',
                                                md: '55px',
                                                sm: '40px',
                                                xs: '40px',
                                            },
                                        }}
                                    >
                                        Join Now
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </Box>
    );
};

export default JoinUs;
