import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import Colors from '../../../utils/Colors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OurTeamCard from '../../../components/OurTeamSmallCard/OurTeamCard';
import { useQuery } from '@tanstack/react-query';
import { getObjects } from '../../../api/Api';
import { Link } from 'react-router-dom';
import HomeTeamLoading from '../../../components/SkeletonLoading/HomeSectionLoading/HomeTeamLoading';
import { useAppContext } from '../../../context/AppContext';

const OurTeam = () => {
    const { value, setValue } = useAppContext();
    const { data, isError, isLoading, isSuccess } = useQuery(['our-team'], () =>
        getObjects('team')
    );
    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <HomeTeamLoading />;
    }
    if (isSuccess) {
        return (
            <Box sx={{ backgroundColor: Colors.SECTION_BACKGROUND_COLOR }}>
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            pt: 10,
                            pb: 10,
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '30px',
                                        lg: '30px',
                                        md: '25px',
                                        sm: '20px',
                                        xs: '20px',
                                    },
                                }}
                            >
                                Meet Our
                                <span
                                    style={{
                                        color: Colors.PRIMARY,
                                        fontWeight: 1000,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                    }}
                                >
                                    Team
                                </span>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: { xl: '700px' },
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'center',
                                    fontSize: {
                                        xl: '15px',
                                        lg: '15px',
                                        md: '12px',
                                        sm: '10px',
                                        xs: '10px',
                                    },
                                }}
                            >
                                we believe in the power of collaboration and
                                expertise. Our team is comprised of dedicated
                                professionals who are passionate about
                                delivering exceptional results to our clients.
                                Get to know the faces behind our company:
                            </Typography>
                        </Box>
                        <Grid container spacing={2} marginTop={2}>
                            {data.slice(0, 4).map((item, index) => (
                                <Grid
                                    item
                                    xl={3}
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={6}
                                    key={index}
                                >
                                    <OurTeamCard data={item} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Container>
            </Box>
        );
    }
};

export default OurTeam;
