import {
    Box,
    Container,
    Menu,
    MenuItem,
    Paper,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Colors from '../../../utils/Colors';
import HomeIcon from '@mui/icons-material/Home';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import EventIcon from '@mui/icons-material/Event';
import SchoolIcon from '@mui/icons-material/School';
import CallIcon from '@mui/icons-material/Call';
import InfoIcon from '@mui/icons-material/Info';
import { useQuery } from '@tanstack/react-query';
import { getObjects } from '../../../api/Api';
import MenuBarLoading from '../../../components/SkeletonLoading/MenuBarLoading';
import { useAppContext } from '../../../context/AppContext';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';

const NavBar = () => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const { value, setValue } = useAppContext();

    const handleServiceTabClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { data, isLoading, isError, isSuccess } = useQuery(['menu'], () => {
        return getObjects('service');
    });
    if (isLoading) {
        return <MenuBarLoading />;
    }

    if (isError) {
        return <Typography>Error</Typography>;
    }
    if (isSuccess) {
        return (
            <Box>
                <Paper
                    sx={{ backgroundColor: Colors.SECTION_BACKGROUND_COLOR }}
                >
                    <Container>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="primary"
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="secondary tabs example"
                        >
                            <Tab
                                value="home"
                                icon={<HomeIcon />}
                                iconPosition="start"
                                label={
                                    <Link
                                        to="/"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        Home
                                    </Link>
                                }
                                sx={boldTabsStyle}
                            />
                            <Tab
                                icon={<RoomServiceIcon />}
                                iconPosition="start"
                                label="Service"
                                value={'service'}
                                onClick={handleServiceTabClick}
                                sx={boldTabsStyle}
                            />
                            <Tab
                                icon={<SchoolIcon />}
                                iconPosition="start"
                                value="scholarship"
                                label={
                                    <Link
                                        to={'/scholarship/'}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        Scholarships
                                    </Link>
                                }
                                sx={boldTabsStyle}
                            />
                            <Tab
                                icon={<AutoStoriesIcon />}
                                iconPosition="start"
                                value="project"
                                label={
                                    <Link
                                        to={'/project/'}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        Projects
                                    </Link>
                                }
                                sx={boldTabsStyle}
                            />
                            <Tab
                                icon={<HistoryEduIcon />}
                                iconPosition="start"
                                value="blog"
                                label={
                                    <Link
                                        to={'/blog/'}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        Blogs
                                    </Link>
                                }
                                sx={boldTabsStyle}
                            />
                            <Tab
                                icon={<EventIcon />}
                                iconPosition="start"
                                value="event"
                                label={
                                    <Link
                                        to={'/event/'}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        Events
                                    </Link>
                                }
                                sx={boldTabsStyle}
                            />
                            <Tab
                                icon={<StarBorderPurple500Icon />}
                                iconPosition="start"
                                value="success-story"
                                label={
                                    <Link
                                        to="/success-story/"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        Success Story
                                    </Link>
                                }
                                sx={boldTabsStyle}
                            />

                            <Tab
                                icon={<DomainVerificationIcon />}
                                iconPosition="start"
                                value="verification"
                                label={
                                    <Link
                                        to="/verification/"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        Verifications
                                    </Link>
                                }
                                sx={boldTabsStyle}
                            />
                            <Tab
                                icon={<CallIcon />}
                                iconPosition="start"
                                value="contact"
                                label={
                                    <Link
                                        to="/contact/"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        Contact
                                    </Link>
                                }
                                sx={boldTabsStyle}
                            />

                            <Tab
                                icon={<InfoIcon />}
                                iconPosition="start"
                                value="about"
                                label={
                                    <Link
                                        to={'/about/'}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        About
                                    </Link>
                                }
                                sx={boldTabsStyle}
                            />
                        </Tabs>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            {data.map((item, index) => (
                                <Link
                                    key={index}
                                    to={`/service/details/${item.id}/`}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    }}
                                >
                                    <MenuItem onClick={handleMenuClose}>
                                        {item.name}
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Container>
                </Paper>
            </Box>
        );
    }
};
const boldTabsStyle = {
    fontWeight: 'bold',
};

export default NavBar;
