import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import ComputerIcon from '@mui/icons-material/Computer';
import Colors from '../../utils/Colors';

const ServicesCard = (props) => {
    const data = props.data;
    return (
        <Paper
            sx={{
                width: {
                    xl: '350px',
                    lg: '350px',
                    md: '350px',
                    sm: '100%',
                    xs: '100%',
                },
                height: '350px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    p: 2,
                }}
            >
                <Box
                    component={'img'}
                    src={data.image}
                    alt={
                        data.image &&
                        data.image.substring(data.image.lastIndexOf('/') + 1)
                    }
                    sx={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                    }}
                />
                <Typography fontWeight={'bold'}>{data.title}</Typography>
                <Typography variant="body2" textAlign={'center'}>
                    {data.description}
                </Typography>
            </Box>
        </Paper>
    );
};

export default ServicesCard;
