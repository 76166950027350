import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Colors from '../../../utils/Colors';

const HomeTeamLoading = () => {
    return (
        <Box sx={{ backgroundColor: Colors.SECTION_BACKGROUND_COLOR }}>
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        pt: 10,
                        pb: 10,
                    }}
                >
                    <Box
                        sx={{
                            width: '250px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '15px',
                            height: '40px',
                            backgroundColor: Colors.PRIMARY_BACKGROUND_LIGHT,
                        }}
                    >
                        <Skeleton variant="text" width="50%" height={40} />
                    </Box>
                    <Box>
                        <Skeleton variant="text" width="50%" height={40} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            width: { xl: '700px' },
                        }}
                    >
                        <Skeleton variant="text" width="100%" height={40} />
                    </Box>
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            {[1, 2, 3].map((item, index) => (
                                <Grid
                                    item
                                    xl={3}
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={6}
                                    key={index}
                                >
                                    <Box sx={{ height: '400px' }}>
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100%"
                                            sx={{
                                                borderRadius: '50%',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        mt: 5,
                                    }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width="200px"
                                        height="60px"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default HomeTeamLoading;
