import React from 'react';
import {
    Box,
    Breadcrumbs,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SchoolIcon from '@mui/icons-material/School';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Colors from '../../../utils/Colors';

const JobDetailsLoading = () => {
    return (
        <Box
            sx={{
                backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                minHeight: '800px',
            }}
        >
            <Container>
                <Box>
                    <Breadcrumbs sx={{ pt: 10 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: Colors.PRIMARY,
                                textDecoration: 'none',
                                gap: 5,
                            }}
                        >
                            <KeyboardBackspaceIcon
                                sx={{ mr: 1 }}
                                fontSize="inherit"
                            />
                            <Skeleton variant="text" width={120} height={20} />
                        </Box>
                    </Breadcrumbs>
                </Box>
                <Box mt={5}>
                    <Paper
                        sx={{
                            width: '100%',
                            minHeight: '900px',
                            borderRadius: '10px',
                            position: 'relative',
                        }}
                    >
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '82px',
                                height: '82px',
                                borderRadius: '50%',
                                justifyContent: 'center',
                                position: 'absolute',
                                left: {
                                    xl: '46%',
                                    lg: '46%',
                                    md: '46%',
                                    sm: '46%',
                                    xs: '41%',
                                },
                                top: -30,
                            }}
                        >
                            <Skeleton
                                variant="circular"
                                width={80}
                                height={80}
                            />
                        </Paper>
                        <Container>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    pt: 10,
                                }}
                            >
                                <Typography variant="h5" fontWeight={'bold'}>
                                    <Skeleton variant="text" width={200} />
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    pt: 1,
                                }}
                            >
                                <Typography variant="h5" fontWeight={'bold'}>
                                    <Skeleton variant="text" width={150} />
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    minHeight: '90px',
                                    borderTop: '0.2px solid lightGrey',
                                    borderBottom: '0.2px solid lightGrey',
                                    display: 'flex',
                                    flexDirection: {
                                        xl: 'row',
                                        lg: 'row',
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column',
                                    },
                                    alignItems: {
                                        xl: 'center',
                                        lg: 'center',
                                        md: 'center',
                                        sm: 'center',
                                        xs: 'start',
                                    },
                                    gap: {
                                        xl: 4,
                                        lg: 4,
                                        md: 4,
                                        sm: 4,
                                        xs: 2,
                                    },
                                    mt: 5,
                                    pb: {
                                        xl: 0,
                                        lg: 0,
                                        md: 0,
                                        sm: 0,
                                        xs: 3,
                                    },
                                    pt: {
                                        xl: 0,
                                        lg: 0,
                                        md: 0,
                                        sm: 0,
                                        xs: 3,
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                >
                                    <SchoolIcon sx={{ color: 'grey' }} />
                                    <Typography
                                        sx={{
                                            color: 'grey',
                                            fontSize: {
                                                xl: '18px',
                                                lg: '18px',
                                                md: '16px',
                                                sm: '14px',
                                                xs: '12px',
                                            },
                                        }}
                                    >
                                        <Skeleton variant="text" width={100} />
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                >
                                    <LocationOnIcon sx={{ color: 'grey' }} />
                                    <Typography
                                        sx={{
                                            color: 'grey',
                                            fontSize: {
                                                xl: '18px',
                                                lg: '18px',
                                                md: '16px',
                                                sm: '14px',
                                                xs: '12px',
                                            },
                                        }}
                                    >
                                        <Skeleton variant="text" width={100} />
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                >
                                    <AccessTimeFilledIcon
                                        sx={{ color: 'grey' }}
                                    />
                                    <Typography
                                        sx={{
                                            color: 'grey',
                                            fontSize: {
                                                xl: '18px',
                                                lg: '18px',
                                                md: '16px',
                                                sm: '14px',
                                                xs: '12px',
                                            },
                                        }}
                                    >
                                        <Skeleton variant="text" width={100} />
                                    </Typography>
                                </Box>
                            </Box>
                            <Box mt={4}>
                                <Grid container>
                                    <Grid
                                        item
                                        xl={8}
                                        lg={8}
                                        md={6}
                                        sm={6}
                                        xs={12}
                                        sx={{
                                            order: {
                                                xl: 1,
                                                lg: 1,
                                                md: 1,
                                                sm: 1,
                                                xs: 2,
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 4,
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    fontWeight={'bold'}
                                                    variant="h5"
                                                >
                                                    <Skeleton />
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    mt={2}
                                                    sx={{ width: '90%' }}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        width={300}
                                                    />
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography
                                                    fontWeight={'bold'}
                                                    variant="h5"
                                                >
                                                    <Skeleton />
                                                </Typography>
                                                <List
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 360,
                                                    }}
                                                >
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Skeleton
                                                                    variant="text"
                                                                    width={200}
                                                                />
                                                            }
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Skeleton
                                                                    variant="text"
                                                                    width={200}
                                                                />
                                                            }
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Skeleton
                                                                    variant="text"
                                                                    width={200}
                                                                />
                                                            }
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Box>
                                            <Box>
                                                <Typography
                                                    fontWeight={'bold'}
                                                    variant="h5"
                                                >
                                                    <Skeleton />
                                                </Typography>
                                                <List
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 360,
                                                    }}
                                                >
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Skeleton
                                                                    variant="text"
                                                                    width={200}
                                                                />
                                                            }
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Skeleton
                                                                    variant="text"
                                                                    width={200}
                                                                />
                                                            }
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                <Skeleton
                                                                    variant="text"
                                                                    width={200}
                                                                />
                                                            }
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Box>
                                            <Box>
                                                <Typography
                                                    fontWeight={'bold'}
                                                    variant="h5"
                                                >
                                                    <Skeleton />
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    mt={2}
                                                    sx={{ width: '90%' }}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        width={300}
                                                    />
                                                </Typography>
                                            </Box>
                                            <Box mb={4}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    width={200}
                                                    height={40}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xl={4}
                                        lg={4}
                                        md={6}
                                        sm={6}
                                        xs={12}
                                        sx={{
                                            order: {
                                                xl: 2,
                                                lg: 2,
                                                md: 2,
                                                sm: 2,
                                                xs: 1,
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 2,
                                                mb: 3,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    height: '60px',
                                                    justifyContent:
                                                        'space-between',
                                                    borderBottom:
                                                        '1px solid lightGrey',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: 'grey' }}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        width={100}
                                                    />
                                                </Typography>
                                                <Skeleton
                                                    variant="text"
                                                    width={120}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    height: '60px',
                                                    justifyContent:
                                                        'space-between',
                                                    borderBottom:
                                                        '1px solid lightGrey',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: 'grey' }}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        width={100}
                                                    />
                                                </Typography>
                                                <Skeleton
                                                    variant="text"
                                                    width={120}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    height: '60px',
                                                    justifyContent:
                                                        'space-between',
                                                    borderBottom:
                                                        '1px solid lightGrey',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: 'grey' }}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        width={100}
                                                    />
                                                </Typography>
                                                <Skeleton
                                                    variant="text"
                                                    width={120}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    height: '60px',
                                                    justifyContent:
                                                        'space-between',
                                                    borderBottom:
                                                        '1px solid lightGrey',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: 'grey' }}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        width={100}
                                                    />
                                                </Typography>
                                                <Skeleton
                                                    variant="text"
                                                    width={120}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    height: '60px',
                                                    justifyContent:
                                                        'space-between',
                                                    borderBottom:
                                                        '1px solid lightGrey',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: 'grey' }}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        width={100}
                                                    />
                                                </Typography>
                                                <Skeleton
                                                    variant="text"
                                                    width={120}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    height: '60px',
                                                    justifyContent:
                                                        'space-between',
                                                    borderBottom:
                                                        '1px solid lightGrey',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: 'grey' }}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        width={100}
                                                    />
                                                </Typography>
                                                <Skeleton
                                                    variant="text"
                                                    width={120}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    height: '60px',
                                                    justifyContent:
                                                        'space-between',
                                                    borderBottom:
                                                        '1px solid lightGrey',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: 'grey' }}
                                                >
                                                    <Skeleton
                                                        variant="text"
                                                        width={100}
                                                    />
                                                </Typography>
                                                <Skeleton
                                                    variant="text"
                                                    width={120}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
};

export default JobDetailsLoading;
