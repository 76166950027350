import axios from 'axios';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'XCSRF-TOKEN';

const apiBaseUrl = process.env.REACT_APP_API_URL;
const Api = axios.create({ baseURL: apiBaseUrl });

export const getObjects = async (endpoint, filter = '') => {
    const response = await Api.get(`/${endpoint}/?search=${filter}`);
    return response.data;
};

export const getObject = async (endPoint, id) => {
    const response = await Api.get(`/${endPoint}/${id}/`);
    return response.data;
};

export const addObject = async (endPoint, modelObject) => {
    return await Api.post(`/${endPoint}/`, modelObject);
};
