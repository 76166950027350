import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Colors from '../../../utils/Colors';

const HomeArticleLoading = () => {
    return (
        <Box pt={10}>
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: {
                                xl: 'start',
                                lg: 'start',
                                md: 'start',
                                sm: 'center',
                                xs: 'center',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '20px',
                                height: '40px',
                                backgroundColor:
                                    Colors.PRIMARY_BACKGROUND_LIGHT,
                            }}
                        >
                            <Skeleton variant="text" width="50%" height={40} />
                        </Box>
                    </Box>
                    <Box>
                        <Skeleton variant="text" width="50%" height={40} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            width: { xl: '700px' },
                        }}
                    >
                        <Skeleton variant="text" width="100%" height={40} />
                    </Box>
                    <Box sx={{ pt: 3, pb: 3 }}>
                        <Grid container>
                            {[1, 2].map((index) => (
                                <Grid
                                    item
                                    key={index}
                                    xl={8}
                                    lg={8}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <Box sx={{ height: '300px' }}>
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100%"
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box sx={{ pt: 3, pb: 3 }}>
                        <Grid container spacing={1}>
                            {[1, 2].map((index) => (
                                <Grid
                                    item
                                    key={index}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <Box sx={{ height: '300px' }}>
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100%"
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: Colors.PRIMARY,
                            color: Colors.WHITE,
                            width: '250px',
                            height: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '15px',
                            gap: 2,
                        }}
                    >
                        <Skeleton variant="text" width="70%" height={40} />
                        <Skeleton
                            variant="rectangular"
                            width="20%"
                            height={40}
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default HomeArticleLoading;
