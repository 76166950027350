export default {
    PRIMARY: '#0a73c0',
    PRIMARY_LIGHT: '#2daae2',
    SECONDARY: '#e7302a',
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    PRIMARY_BACKGROUND_LIGHT: '#ecf4fa',
    SECTION_BACKGROUND_COLOR: '#f8f9fa',
    BACKGROUND_CARD: '#f5f5f5',
};
