import { Box, Container, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';

const SkeletonLoading = () => {
    return (
        <Box>
            <Box
                sx={{
                    height: '400px',
                    backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: { xl: '50%', lg: '50%', xs: '80%' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3,
                    }}
                >
                    <Typography
                        variant="h4"
                        fontWeight={'bold'}
                        sx={{
                            textAlign: 'center',
                            color: Colors.PRIMARY,
                            fontSize: { xl: '45px', xs: '30px' },
                        }}
                    >
                        Loading...
                    </Typography>
                </Box>
            </Box>
            <Container sx={{ mt: 5 }}>
                <Grid container rowSpacing={6} columnSpacing={3}>
                    {[1, 2, 3, 4, 5].map((index) => (
                        <Grid
                            item
                            key={index}
                            xl={4}
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={400}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default SkeletonLoading;
