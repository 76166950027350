import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Layout from '../pages/layout';
import Home from '../pages/home';
import Contact from '../pages/contact/Contact';
import Job from '../pages/job/Job';
import JobDetails from '../pages/job/JobDetails';
import JobApply from '../pages/job/JobApply';
import Blog from '../pages/blog/Blog';
import BlogDetails from '../pages/blog/BlogDetails';
import About from '../pages/about/About';
import Project from '../pages/project/Project';
import Scholarship from '../pages/scholarship/Scholarship';
import OurTeam from '../pages/team/OurTeam';
import Event from '../pages/event/Event';
import EventDetails from '../pages/event/EventDetails';
import Service from '../pages/service/Service';
import ServiceDetails from '../pages/service/serviceDetails/ServiceDetails';
import NotFoundPage from '../pages/not-found-page/NotFoundPage';
import SuccessStory from '../pages/success-story/SuccessStory';
import SuccessStoryDetails from '../pages/success-story/SuccessStoryDetails';
import Verification from '../pages/verification/Verification';
import Profile from '../pages/verification/Profile';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        // Smooth scrolling animation
        const scrollToTop = () => {
            const c =
                document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            }
        };
        scrollToTop();
    }, [pathname]);

    return null;
};
const Router = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="" element={<Layout />}>
                    <Route path="" element={<Home />} />
                    <Route path="/service/" element={<Service />} />
                    <Route
                        path="/service/details/:id/"
                        element={<ServiceDetails />}
                    />
                    <Route path="/project/" element={<Project />} />
                    <Route path="/blog/" element={<Blog />} />
                    <Route
                        path="/blog/details/:id/"
                        element={<BlogDetails />}
                    />
                    <Route path="/event/" element={<Event />} />
                    <Route
                        path="/event/details/:id/"
                        element={<EventDetails />}
                    />
                    <Route path="/our-team/" element={<OurTeam />} />
                    <Route path="/scholarship/" element={<Scholarship />} />
                    <Route path="/success-story/" element={<SuccessStory />} />
                    <Route
                        path="/success-story/details/:id/"
                        element={<SuccessStoryDetails />}
                    />
                    <Route path="/job/" element={<Job />} />
                    <Route path="/job/details/:id/" element={<JobDetails />} />
                    <Route path="/job/apply/:id/" element={<JobApply />} />

                    <Route path="/verification/" element={<Verification />} />
                    <Route path="/verification/:id" element={<Profile />} />


                    <Route path="/contact/" element={<Contact />} />
                    <Route path="/about/" element={<About />} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
