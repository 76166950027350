import React from 'react';
import { Box, Container, Grid, Rating, Typography } from '@mui/material';
import Colors from '../../../utils/Colors';
import { getObjects } from '../../../api/Api';
import { useQuery } from '@tanstack/react-query';
import HomeTestimonialLoading from '../../../components/SkeletonLoading/HomeSectionLoading/HomeTestimonialLoading';
import TestimonialUserCard from '../../../components/TestimonialUserCard/TestimonialUserCard';

const Testimonial = () => {
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}images/worldMap/world-map.png`;
    const { data, isError, isLoading, isSuccess } = useQuery(
        ['testimonial'],
        () => getObjects('testimonial')
    );
    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <HomeTestimonialLoading />;
    }
    if (isSuccess) {
        return (
            <Box
                sx={{
                    minHeight: '900px',
                    borderTop: '0.5px solid lightGrey',
                    backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                    backgroundImage: `url(${imageUrl})`,
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                    mt: 8,
                }}
            >
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 3,
                            pt: 5,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: {
                                            xl: '30px',
                                            lg: '30px',
                                            md: '25px',
                                            sm: '20px',
                                            xs: '16px',
                                        },
                                        textAlign: 'center',
                                    }}
                                >
                                    See What Our Respected
                                    <span
                                        style={{
                                            color: Colors.PRIMARY,
                                            fontWeight: 1000,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                        }}
                                    >
                                        Clients
                                    </span>
                                    Say About Us
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        width: { xl: '700px' },
                                        textAlign: 'center',
                                        fontSize: {
                                            xl: '20px',
                                            lg: '20px',
                                            md: '20px',
                                            sm: '16px',
                                            xs: '12px',
                                        },
                                    }}
                                >
                                    We welcome valuable feedbacks of our Clients
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Rating
                                    name="read-only"
                                    value={4.5}
                                    precision={0.5}
                                    readOnly
                                />
                                <Typography>(4.8/5)</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                {data.slice(0, 6).map((item, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        xl={4}
                                        lg={4}
                                        md={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <TestimonialUserCard data={item} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    }
};

export default Testimonial;
