import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import ArticleCard from '../../../components/ArticleCard/ArticleCard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useQuery } from '@tanstack/react-query';
import { getObjects } from '../../../api/Api';
import Colors from '../../../utils/Colors';
import { Link } from 'react-router-dom';
import BlogCard from '../../../components/BlogCard/BlogCard';
import HomeArticleLoading from '../../../components/SkeletonLoading/HomeSectionLoading/HomeArticleLoading';
import { useAppContext } from '../../../context/AppContext';

const Article = () => {
    const { value, setValue } = useAppContext();
    const { data, isError, isLoading, isSuccess } = useQuery(['article'], () =>
        getObjects('blog')
    );
    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <HomeArticleLoading />;
    }
    if (isSuccess) {
        return (
            <Box pt={10}>
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '35px',
                                        lg: '35px',
                                        md: '30px',
                                        sm: '25px',
                                        xs: '25px',
                                    },
                                    textAlign: {
                                        xl: 'left',
                                        lg: 'left',
                                        md: 'left',
                                        sm: 'center',
                                        xs: 'center',
                                    },
                                }}
                            >
                                Browse Our Latest
                                <span
                                    style={{
                                        color: Colors.PRIMARY,
                                        fontWeight: 1000,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                    }}
                                >
                                    Articles
                                </span>
                                & News
                            </Typography>
                        </Box>
                        <Grid container>
                            <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 4,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            width: '600px',
                                            width: { xl: '700px' },
                                            textAlign: {
                                                xl: 'left',
                                                lg: 'left',
                                                md: 'left',
                                                sm: 'center',
                                                xs: 'center',
                                            },
                                        }}
                                    >
                                        We share insight, stories and expertise
                                        on verity of topics relevant to IT,
                                        Capacity Building, Education. text &
                                        finance, Water, sensation, Hygiene(WASH)
                                    </Typography>
                                    <Link
                                        to={'/blog/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Box
                                            sx={{
                                                display: {
                                                    xl: 'none',
                                                    lg: 'none',
                                                    md: 'none',
                                                    sm: 'flex',
                                                    xs: 'flex',
                                                },
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Button
                                                onClick={() => setValue('blog')}
                                                sx={{
                                                    backgroundColor:
                                                        Colors.PRIMARY,
                                                    color: Colors.WHITE,
                                                    width: '250px',
                                                    height: '50px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: '10px',
                                                    gap: 1,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        borderRight:
                                                            '0.2px solid white',
                                                        pr: 1,
                                                    }}
                                                >
                                                    Brows More Articles
                                                </Typography>
                                                <ArrowForwardIcon />
                                            </Button>
                                        </Box>
                                    </Link>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {
                                                lg: 'row',
                                                xs: 'column',
                                            },
                                            gap: 2,
                                        }}
                                    >
                                        {data.slice(0, 2).map((item) => (
                                            <Link
                                                key={item.id}
                                                to={`/blog/details/${item.id}`}
                                                style={{
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                <BlogCard data={item} />
                                            </Link>
                                        ))}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Link
                                        to={'/blog/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Box
                                            sx={{
                                                display: {
                                                    xl: 'flex',
                                                    lg: 'flex',
                                                    md: 'flex',
                                                    sm: 'none',
                                                    xs: 'none',
                                                },
                                                justifyContent: 'end',
                                                pb: 2,
                                            }}
                                        >
                                            <Button
                                                onClick={() => setValue('blog')}
                                                sx={{
                                                    backgroundColor:
                                                        Colors.PRIMARY,
                                                    color: Colors.WHITE,
                                                    width: '250px',
                                                    height: '50px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: '10px',
                                                    gap: 1,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        borderRight:
                                                            '0.2px solid white',
                                                        pr: 1,
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Browse More Articles
                                                </Typography>
                                                <ArrowForwardIcon />
                                            </Button>
                                        </Box>
                                    </Link>
                                    <Grid container spacing={1} mt={3}>
                                        {data.slice(2, 5).map((item) => (
                                            <Grid
                                                mt={1}
                                                key={item.id}
                                                item
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Link
                                                    to={`/blog/details/${item.id}`}
                                                    style={{
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    <ArticleCard data={item} />
                                                </Link>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        );
    }
};

export default Article;
