import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const ServiceLoading = () => {
    return (
        <Box>
            <Box
                sx={{
                    height: '400px', // Adjust height as needed
                    backgroundColor: '#f0f0f0', // Adjust background color
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: { xl: '50%', lg: '50%', xs: '80%' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3,
                    }}
                >
                    <Skeleton variant="text" width="50%" height={60} />
                    <Skeleton variant="rectangular" width="80%" height={100} />
                </Box>
            </Box>
            <Container sx={{ mt: 5 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Skeleton variant="text" width="50%" height={60} />
                </Box>
                <Grid container columnSpacing={2} rowSpacing={3} mt={3}>
                    {[1, 2, 3].map((item, index) => (
                        <Grid
                            item
                            key={index}
                            xl={4}
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={300}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default ServiceLoading;
