import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Colors from '../../../utils/Colors';

const HomeServiceLoading = () => {
    return (
        <Box>
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        mt: 8,
                    }}
                >
                    <Box
                        sx={{
                            width: '250px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '15px',
                            height: '40px',
                            backgroundColor: Colors.PRIMARY_BACKGROUND_LIGHT,
                        }}
                    >
                        <Skeleton variant="text" width="50%" height={40} />
                    </Box>
                    <Box>
                        <Skeleton variant="text" width="50%" height={40} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            width: { xl: '700px' },
                        }}
                    >
                        <Skeleton variant="text" width="100%" height={40} />
                    </Box>
                    <Box sx={{ pt: 3, pb: 3 }}>
                        <Grid container spacing={1}>
                            {[1, 2, 3, 4].map((index) => (
                                <Grid
                                    item
                                    key={index}
                                    xl={3}
                                    lg={3}
                                    md={3}
                                    sm={6}
                                    xs={6}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height="100%"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: Colors.PRIMARY,
                            color: Colors.WHITE,
                            width: '250px',
                            height: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '15px',
                            gap: 2,
                        }}
                    >
                        <Skeleton variant="text" width="70%" height={40} />
                        <Skeleton
                            variant="rectangular"
                            width="20%"
                            height={40}
                        />
                    </Box>
                </Box>
            </Container>
            <Box sx={{ borderBottom: '1px solid lightGrey', pt: 5, pb: 5 }} />
        </Box>
    );
};

export default HomeServiceLoading;
