import React from 'react';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Colors from '../../../utils/Colors';

const JobLoading = () => {
    return (
        <Box
            sx={{
                backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                minHeight: '1000px',
            }}
        >
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        mb: 4,
                    }}
                >
                    <Skeleton variant="text" width="70%" height={40} />
                </Box>
                <Grid container spacing={4}>
                    <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                            <Skeleton variant="rectangular" height={'100px'} />
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Paper
                            sx={{
                                width: '100%',
                                height: '250px',
                                border: '1px solid lightGrey',
                                borderRadius: '10px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    p: 2,
                                }}
                            >
                                <Typography variant="h6" fontWeight={'bold'}>
                                    <Skeleton variant="text" />
                                </Typography>
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default JobLoading;
