import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CallIcon from '@mui/icons-material/Call';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Colors from '../../utils/Colors';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import TagIcon from '@mui/icons-material/Tag';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { addObject } from '../../api/Api';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';

const Contact = () => {
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}images/png/destination.png`;
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [fullNameError, setFullNameError] = useState();
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState();
    const [messageError, setMessageError] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();

        let isValid = true;

        // Validation for Full Name
        if (!fullName || fullName.trim() === '') {
            setFullNameError('Full Name cannot be empty');
            isValid = false;
        } else {
            setFullNameError();
        }

        // Validation for Email
        if (!email || email.trim() === '') {
            setEmailError('Email cannot be empty');
            isValid = false;
        } else if (!validateEmail(email)) {
            setEmailError('Invalid email address');
            isValid = false;
        } else {
            setEmailError();
        }

        // Validation for Phone Number
        if (!phoneNumber || phoneNumber.trim() === '') {
            setPhoneNumberError('Phone Number cannot be empty');
            isValid = false;
        } else if (!validatePhoneNumber(phoneNumber)) {
            setPhoneNumberError('Invalid phone number');
            isValid = false;
        } else {
            setPhoneNumberError();
        }

        // Validation for Message
        if (!message || message.trim() === '') {
            setMessageError('Message cannot be empty');
            isValid = false;
        } else {
            setMessageError();
        }

        if (isValid) {
            // Proceed with form submission if all fields are valid
            let formData = new FormData();
            formData.append('fullName', fullName);
            formData.append('email', email);
            formData.append('phoneNumber', phoneNumber);
            formData.append('message', message);

            // send data to api
            addContactUs.mutate(formData);
        }
    };

    const validatePhoneNumber = (phoneNumber) => {
        // Regular expression for Afghanistan phone numbers
        const afghanPhoneNumberRegex = /^(\+93|0)?[7][0-9]{8}$/;
        return afghanPhoneNumberRegex.test(phoneNumber);
    };
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    // send data to api using react query
    const addContactUs = useMutation((data) => addObject('contact-us', data), {
        onSuccess: () => {
            enqueueSnackbar('Successfully sent!', { variant: 'success' });
            navigate('/');
        },
        onError: () => {
            enqueueSnackbar('Occur an error', { variant: 'error' });
        },
    });

    const sendMail = () => {
        window.location.href = 'mailto:info@afghancosoms.com';
    };

    return (
        <Container>
            <MetaTags>
                <title>Afghan cosmos - Contact</title>
                <meta name="description" content="Brows our contact" />
            </MetaTags>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} mt={5}>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <Box>
                            <Paper
                                elevation={3}
                                sx={{
                                    width: '100%',
                                    height: '250px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                }}
                            >
                                <Box>
                                    <CallIcon
                                        sx={{
                                            color: Colors.PRIMARY,
                                            fontSize: '50px',
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        fontWeight={'bold'}
                                    >
                                        Call us
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            textAlign: 'center',
                                            width: '80%',
                                        }}
                                    >
                                        Intrusted in Afghan Cosmos? just pick up
                                        the phone to chat with use
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 3 }}>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            display: 'flex',
                                            borderRadius: '5px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <SmartphoneIcon
                                            sx={{ color: Colors.PRIMARY }}
                                        />
                                        <Typography
                                            sx={{
                                                color: Colors.PRIMARY,
                                                fontWeight: 'bold',
                                                fontSize: {
                                                    xl: '20px',
                                                    lg: '20px',
                                                    md: '10px',
                                                    sm: '10px',
                                                    xs: '15px',
                                                },
                                            }}
                                        >
                                            +93744416777
                                        </Typography>
                                    </Paper>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            display: 'flex',
                                            borderRadius: '5px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CallIcon
                                            sx={{ color: Colors.PRIMARY }}
                                        />
                                        <Typography
                                            sx={{
                                                color: Colors.PRIMARY,
                                                fontWeight: 'bold',
                                                fontSize: {
                                                    xl: '20px',
                                                    lg: '20px',
                                                    md: '10px',
                                                    sm: '10px',
                                                    xs: '15px',
                                                },
                                            }}
                                        >
                                            +93202202825
                                        </Typography>
                                    </Paper>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <Box>
                            <Paper
                                elevation={3}
                                sx={{
                                    width: '100%',
                                    height: '250px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                }}
                            >
                                <Box>
                                    <MarkEmailReadIcon
                                        sx={{
                                            color: Colors.SECONDARY,
                                            fontSize: '50px',
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        fontWeight={'bold'}
                                    >
                                        Email us
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            textAlign: 'center',
                                            width: '80%',
                                        }}
                                    >
                                        can't chat with us during these hours?
                                        We'll respond to you via email within a
                                        day
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Button
                                        onClick={sendMail}
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            backgroundColor: Colors.WHITE,
                                        }}
                                    >
                                        <EmailIcon
                                            sx={{ color: Colors.BLACK }}
                                        />
                                        <Typography
                                            sx={{
                                                color: Colors.BLACK,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Email
                                        </Typography>
                                    </Button>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <Box>
                            <Paper
                                elevation={3}
                                sx={{
                                    width: '100%',
                                    height: '250px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                }}
                            >
                                <Box>
                                    <TagIcon
                                        sx={{
                                            fontSize: '50px',
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        fontWeight={'bold'}
                                    >
                                        Social Media
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            textAlign: 'center',
                                            width: '80%',
                                        }}
                                    >
                                        Visit our social media pages
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <a
                                        href="https://www.facebook.com/AfghanCosmosGroup?mibextid=kFxxJD"
                                        target="_blank"
                                    >
                                        <Typography
                                            component="span"
                                            color="inherit"
                                        >
                                            <FacebookIcon fontSize="small" />
                                        </Typography>
                                    </a>
                                    <a
                                        href="https://x.com/AfghanCosmos?t=RJYwppQl7EcJRUXzAwHXzA&s=09"
                                        target="_blank"
                                    >
                                        <XIcon fontSize="small" />
                                    </a>
                                    <a
                                        href="https://www.instagram.com/afghan_cosmos_group?igsh=MW8yamI0cXNyb21jYQ=="
                                        target="_blank"
                                    >
                                        <InstagramIcon fontSize="small" />
                                    </a>
                                    <a
                                        href="https://pin.it/1Kij99jvh"
                                        target="_blank"
                                    >
                                        <PinterestIcon fontSize="small" />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/afghan-cosmos-group/"
                                        target="_blank"
                                    >
                                        <LinkedInIcon fontSize="small" />
                                    </a>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        mt={6}
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: {
                                                xl: '30px',
                                                lg: '30px',
                                                md: '25px',
                                                sm: '20px',
                                                xs: '20px',
                                            },
                                            textAlign: 'center',
                                        }}
                                    >
                                        Let Us Hear From You
                                        <span
                                            style={{
                                                color: Colors.PRIMARY,
                                                fontWeight: 1000,
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                            }}
                                        >
                                            Directly!
                                        </span>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: {
                                                xl: '15px',
                                                lg: '15px',
                                                md: '12px',
                                                sm: '10px',
                                                xs: '10px',
                                            },
                                            width: {
                                                xl: '50%',
                                                lg: '50%',
                                                md: '60%',
                                                sm: '80%',
                                                xs: '90%',
                                            },
                                        }}
                                    >
                                        Need to get in touch with us? Either
                                        fill out the form with your inquiry or
                                        find our email above.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Box
                                    mt={4}
                                    component={'img'}
                                    src={imageUrl}
                                    alt={
                                        imageUrl &&
                                        imageUrl.substring(
                                            imageUrl.lastIndexOf('/') + 1
                                        )
                                    }
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Box
                                    component={'form'}
                                    onSubmit={handleSubmit}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        mt: 4,
                                    }}
                                >
                                    <TextField
                                        label="Full Name"
                                        name="full_name"
                                        error={!!fullNameError}
                                        helperText={fullNameError}
                                        onChange={(e) =>
                                            setFullName(e.target.value)
                                        }
                                    />
                                    <TextField
                                        label="Email"
                                        name="email"
                                        error={!!emailError}
                                        helperText={emailError}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                    <TextField
                                        label="Phone Number"
                                        name="phoneNumber"
                                        error={!!phoneNumberError}
                                        helperText={phoneNumberError}
                                        onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                        }
                                    />
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="Message"
                                        name="message"
                                        error={!!messageError}
                                        helperText={messageError}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        multiline
                                        rows={5}
                                        fullWidth
                                        variant="outlined"
                                    />
                                    {addContactUs.isLoading ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                mt: 1,
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <Box
                                            component={'button'}
                                            sx={{
                                                backgroundColor: Colors.PRIMARY,
                                                color: Colors.WHITE,
                                                width: '200px',
                                                height: '50px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '10px',
                                                cursor: 'pointer',
                                                gap: 2,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    borderRight:
                                                        '0.2px solid white',
                                                    pr: 1,
                                                }}
                                            >
                                                Send Message
                                            </Typography>
                                            <ArrowForwardIcon />
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default Contact;
