import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../../../utils/Colors';
import ServicesCard from '../../../../components/ServicesCard/ServicesCard';

const ServiceDetailsServicesSection = (props) => {
    const data = props.data;
    return (
        <Box sx={{ borderTop: '1px solid lightGrey' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 4,
                }}
            >
                <Box
                    sx={{
                        width: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '15px',
                        height: '40px',
                        backgroundColor: Colors.PRIMARY_BACKGROUND_LIGHT,
                    }}
                >
                    <Typography
                        sx={{
                            color: Colors.PRIMARY,
                            fontSize: {
                                xl: '30px',
                                lg: '30px',
                                md: '25px',
                                sm: '20px',
                                xs: '15px',
                            },
                        }}
                    >
                        Services
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={3} mt={4}>
                {data.map((item, index) => (
                    <Grid item key={index} xl={4} lg={4} md={6} sm={6} xs={12}>
                        <ServicesCard data={item} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ServiceDetailsServicesSection;
