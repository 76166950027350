import React from 'react';
import { Box } from '@mui/material';

const YoutubeVideo = ({ videoLink }) => {
    // Function to extract video ID from YouTube link
    const extractVideoId = (videoLink) => {
        const regExp =
            /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
        const match = videoLink.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    // Extract the video ID from the YouTube link
    const videoId = extractVideoId(videoLink);

    // Construct the embed URL
    const embedUrl = `https://www.youtube-nocookie.com/embed/${videoId}`;

    return (
        <Box>
            <Box
                component={'iframe'}
                src={embedUrl}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                sx={{
                    borderRadius: '10px',
                    width: {
                        xl: '560px',
                        lg: '560px',
                        md: '500px',
                        sm: '400px',
                        xs: '100%',
                    },
                    height: {
                        xl: '315px',
                        lg: '315px',
                        md: '300px',
                        sm: '250px',
                        xs: '200px',
                    },
                }}
            />
        </Box>
    );
};

export default YoutubeVideo;
