import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [value, setValue] = useState('home');

    return (
        <AppContext.Provider value={{ value, setValue }}>
            {children}
        </AppContext.Provider>
    );
};
