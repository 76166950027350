import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import Colors from '../../../utils/Colors';
import ScholarshipCart from '../../../components/ScholarshipCard/ScholarshipCart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getObjects } from '../../../api/Api';
import HomeScholarshipLoading from '../../../components/SkeletonLoading/HomeSectionLoading/HomeScholarshipLoading';
import { useAppContext } from '../../../context/AppContext';

const Scholarship = () => {
    const { value, setValue } = useAppContext();
    const { data, isError, isLoading, isSuccess } = useQuery(
        ['scholarship'],
        () => getObjects('scholarship')
    );
    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <HomeScholarshipLoading />;
    }
    if (isSuccess) {
        return (
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        mt: 8,
                        mb: 5,
                    }}
                >
                    <Box>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: {
                                    xl: '30px',
                                    lg: '30px',
                                    md: '25px',
                                    sm: '20px',
                                    xs: '20px',
                                },
                            }}
                        >
                            Latest
                            <span
                                style={{
                                    color: Colors.PRIMARY,
                                    fontWeight: 1000,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                }}
                            >
                                Scholarships
                            </span>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: 'center',
                                fontSize: {
                                    xl: '15px',
                                    lg: '15px',
                                    md: '12px',
                                    sm: '10px',
                                    xs: '10px',
                                },
                            }}
                        >
                            We are thrilled to introduce range of latest
                            scholarships in your dream location
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Grid container>
                            {data.slice(0, 3).map((item, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <ScholarshipCart data={item} />
                                </Grid>
                            ))}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        mt: 5,
                                    }}
                                >
                                    <Link
                                        to={'/scholarship/'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Button
                                            onClick={() =>
                                                setValue('scholarship')
                                            }
                                            sx={{
                                                backgroundColor: Colors.PRIMARY,
                                                color: Colors.WHITE,
                                                width: '300px',
                                                height: '60px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '5px',
                                                gap: 2,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    borderRight:
                                                        '0.2px solid white',
                                                    pr: 1,
                                                    fontSize: '12px',
                                                }}
                                            >
                                                Browse More Scholarships
                                            </Typography>
                                            <ArrowForwardIcon />
                                        </Button>
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        );
    }
};

export default Scholarship;
