import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

const ProjectCard = (props) => {
    const data = props.data;
    return (
        <Paper
            elevation={3}
            sx={{
                width: '100%',
                minHeight: { xl: '350px', sm: '200px' },
                borderRadius: '10px',
                display: 'flex',
                flexDirection: {
                    xl: 'row',
                    lg: 'row',
                    md: 'row',
                    sm: 'row',
                    xs: 'column',
                },
                gap: 2,
            }}
        >
            <Box
                component={'img'}
                src={data.image}
                alt={
                    data.image &&
                    data.image.substring(data.image.lastIndexOf('/') + 1)
                }
                sx={{
                    width: {
                        xl: '400px',
                        lg: '400px',
                        md: '350px',
                        sm: '250px',
                        xs: '100%',
                    },
                    minHeight: { xl: '350px', sm: '200px' },
                    objectFit: 'cover',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    m: 2,
                }}
            >
                <Typography
                    mt={3}
                    variant="h5"
                    fontWeight={'bold'}
                    sx={{
                        fontSize: {
                            xl: '25px',
                            lg: '25px',
                            md: '23px',
                            sm: '17px',
                            xs: '15px',
                        },
                    }}
                >
                    {data.title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: {
                            xl: '16px',
                            lg: '16px',
                            md: '15px',
                            sm: '10px',
                            xs: '13px',
                        },
                    }}
                >
                    {data.description}
                </Typography>
            </Box>
        </Paper>
    );
};

export default ProjectCard;
