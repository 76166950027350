import { Box, Button, Paper, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Colors from '../../utils/Colors';

const ServiceProfileCard = (props) => {
    const data = props.data;
    return (
        <Paper
            sx={{
                width: { xl: '300px', lg: '300px', xs: '100%' },
                height: '350px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                backgroundColor: Colors.PRIMARY,
                borderRadius: '20px',
            }}
        >
            <Box>
                <Box
                    component={'img'}
                    src={data.image}
                    alt={
                        data.image &&
                        data.image.substring(data.image.lastIndexOf('/') + 1)
                    }
                    sx={{
                        width: '120px',
                        height: '120px',
                        borderRadius: '50%',
                        border: '3px solid white',
                    }}
                />
            </Box>
            <Box>
                <Typography sx={{ color: Colors.WHITE, fontWeight: 'bold' }}>
                    {data.name}
                </Typography>
            </Box>
            <Box sx={{ width: '95%' }}>
                <Typography
                    variant="body2"
                    sx={{ color: Colors.WHITE, textAlign: 'center' }}
                >
                    {data.about.length < 150
                        ? data.about
                        : `${data.about.slice(0, 150)}...`}
                </Typography>
            </Box>
            <Box mt={2}>
                <Link
                    to={`/service/details/${data.id}/`}
                    style={{ textDecoration: 'none' }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            borderRadius: '5px',
                            backgroundColor: Colors.WHITE,
                            color: Colors.BLACK,
                        }}
                    >
                        Read More
                    </Button>
                </Link>
            </Box>
        </Paper>
    );
};

export default ServiceProfileCard;
