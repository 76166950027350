import React from 'react';
import Colors from '../../../../utils/Colors';
import { Box, Grid, Typography } from '@mui/material';
import ProjectCard from '../../../../components/ProjectCard/ProjectCard';

const ServiceDetailsProjectSection = (props) => {
    const data = props.data;
    return (
        <Box sx={{ borderTop: '1px solid lightGrey', mt: 6 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 4,
                }}
            >
                <Box
                    sx={{
                        width: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '15px',
                        height: '40px',
                        backgroundColor: Colors.PRIMARY_BACKGROUND_LIGHT,
                    }}
                >
                    <Typography
                        sx={{
                            color: Colors.PRIMARY,
                            fontSize: {
                                xl: '30px',
                                lg: '30px',
                                md: '25px',
                                sm: '20px',
                                xs: '15px',
                            },
                        }}
                    >
                        Projects
                    </Typography>
                </Box>
            </Box>
            <Grid container rowSpacing={3} mt={4}>
                {data.map((item, index) => (
                    <Grid
                        key={index}
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <ProjectCard data={item} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ServiceDetailsProjectSection;
