import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../../utils/Colors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OurServicesCard from '../../../components/OurServicesCard/OurServicesCard';
import { getObjects } from '../../../api/Api';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import HomeServiceLoading from '../../../components/SkeletonLoading/HomeSectionLoading/HomeServiceLoading';
import { useAppContext } from '../../../context/AppContext';

const Services = () => {
    const { value, setValue } = useAppContext();
    const { data, isError, isLoading, isSuccess } = useQuery(['service'], () =>
        getObjects('service')
    );
    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <HomeServiceLoading />;
    }
    if (isSuccess) {
        return (
            <Box>
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            mt: 8,
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '30px',
                                        lg: '30px',
                                        md: '25px',
                                        sm: '20px',
                                        xs: '20px',
                                    },
                                    textAlign: 'center',
                                }}
                            >
                                Explore Our Unique
                                <span
                                    style={{
                                        color: Colors.PRIMARY,
                                        fontWeight: 1000,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                    }}
                                >
                                    Services
                                </span>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: { xl: '700px' },
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'center',

                                    fontSize: {
                                        xl: '15px',
                                        lg: '15px',
                                        md: '12px',
                                        sm: '10px',
                                        xs: '10px',
                                    },
                                }}
                            >
                                Afghan Cosmos is a leading Organization based in
                                Afghanistan that provides the professional and
                                reliable services in the field of Management,
                                Scholarships, Tax & Finance, IT &
                                Telecommunication, Research, Surveys and Event
                                Management.
                            </Typography>
                        </Box>
                        <Box sx={{ pt: 3, pb: 3 }}>
                            <Grid container spacing={1}>
                                {data.slice(0, 4).map((item, index) => (
                                    <Grid
                                        item
                                        key={index}
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={6}
                                        xs={6}
                                    >
                                        <OurServicesCard data={item} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                        <Link
                            to={'/service/'}
                            style={{ textDecoration: 'none' }}
                        >
                            <Button
                                onClick={() => setValue('service')}
                                sx={{
                                    backgroundColor: Colors.PRIMARY,
                                    color: Colors.WHITE,
                                    width: '250px',
                                    height: '60px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '15px',
                                    gap: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        borderRight: '0.2px solid white',
                                        pr: 1,
                                        fontSize: '12px',
                                    }}
                                >
                                    Browse More Services
                                </Typography>
                                <ArrowForwardIcon />
                            </Button>
                        </Link>
                    </Box>
                </Container>
                <Box
                    sx={{ borderBottom: '1px solid lightGrey', pt: 5, pb: 5 }}
                />
            </Box>
        );
    }
};

export default Services;
