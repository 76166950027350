import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    Avatar,
    Grid,
    Divider,
    Alert,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Function to fetch verification data
const fetchVerification = async (reg_no) => {
    const url = process.env.REACT_APP_API_URL;
    if (!reg_no) {
        throw new Error('No registration number provided.');
    }
    const response = await axios.get(`${url}verification/`, {
        params: { reg_no },
    });
    return response.data;
};

const Profile = () => {
    const { id } = useParams(); // Extract id from the URL

    const { data, error, isLoading } = useQuery(['verification', id], () =>
        fetchVerification(id)
    );

    return (
        <Box
            sx={{
                padding: 4,
                maxWidth: '1000px',
                margin: '0 auto',
                fontFamily: 'Arial, sans-serif',
                animation: 'fadeIn 0.5s ease-in-out',
                '@keyframes fadeIn': {
                    from: { opacity: 0 },
                    to: { opacity: 1 },
                },
            }}
        >
            <Typography
                variant="h4"
                gutterBottom
                sx={{
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                    mb: 3,
                }}
            >
                Profile Details
            </Typography>

            {isLoading && (
                <Box mt={3} sx={{ textAlign: 'center' }}>
                    <CircularProgress size={60} />
                    <Typography sx={{ mt: 2, color: 'textSecondary' }}>
                        Loading profile details...
                    </Typography>
                </Box>
            )}

            {error && (
                <Typography color="error" sx={{ mt: 3, textAlign: 'center' }}>
                    {error.message ||
                        'An error occurred while fetching the data.'}
                </Typography>
            )}
            {data?.message ? (
                <Grid container justifyContent={'center'}>
                    <Alert severity="info">{data?.message}</Alert>
                </Grid>
            ) : (
                <>
                    {data && (
                        <Card
                            sx={{
                                marginTop: 3,
                                backgroundColor: '#fff',
                                borderRadius: '15px',
                                boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                            }}
                        >
                            <CardContent>
                                <Grid container spacing={3}>
                                    {/* Profile Avatar and Basic Info */}
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        sx={{
                                            textAlign: 'center',
                                            borderRight: {
                                                xs: 'none',
                                                md: '1px solid #ddd',
                                            },
                                            padding: 2,
                                        }}
                                    >
                                        <Avatar
                                            alt={`${data.first_name} ${data.last_name}`}
                                            src={
                                                data?.image ||
                                                '/placeholder.png'
                                            } // Fallback image
                                            sx={{
                                                width: 170,
                                                height: 170,
                                                margin: '0 auto',
                                                border: '3px solid #2196f3',
                                            }}
                                        />
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                mt: 2,
                                                fontWeight: 'bold',
                                                color: '#555',
                                            }}
                                        >
                                            {data.first_name} {data.last_name}
                                        </Typography>
                                    </Grid>

                                    {/* Detailed Info Section */}
                                    <Grid
                                        item
                                        xs={12}
                                        md={8}
                                        sx={{ padding: 2 }}
                                    >
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            sx={{
                                                color: '#2196f3',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Basic Information
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>
                                                        Father's Name:
                                                    </strong>{' '}
                                                    {data.father_name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>Gender:</strong>{' '}
                                                    {data.gender === 1
                                                        ? 'Male'
                                                        : 'Female'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>
                                                        National ID:
                                                    </strong>{' '}
                                                    {data.national_id_no}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>Mobile:</strong>{' '}
                                                    {data.mobile}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>Reg No:</strong>{' '}
                                                    {data?.reg_no || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>Address:</strong>{' '}
                                                    {data.address || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>Email:</strong>{' '}
                                                    {data.email || 'N/A'}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            sx={{
                                                mt: 3,
                                                color: '#2196f3',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Course Information
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>Start Date:</strong>{' '}
                                                    {data.start_date}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>End Date:</strong>{' '}
                                                    {data.end_date}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>
                                                        Credential ID:
                                                    </strong>{' '}
                                                    {data.credential_id ||
                                                        'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>Remarks:</strong>{' '}
                                                    {data.remarks || 'None'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
        </Box>
    );
};

export default Profile;
