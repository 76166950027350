import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getObject } from '../../api/Api';
import BlogDetailsLoading from '../../components/SkeletonLoading/BlogLoading/BlogDetailsLoading';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ShareIcon from '@mui/icons-material/Share';
import { Helmet } from 'react-helmet';

const BlogDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    const handleShare = (data) => {
        // Share on Facebook
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            window.location.href
        )}&quote=${encodeURIComponent(data.title)}&picture=${encodeURIComponent(
            data.socialImage || data.images[0].image
        )}&description=${encodeURIComponent(data.description)}`;
        window.open(facebookShareUrl, '_blank');
    };
    const handleDownload = () => {
        const url = data.file;

        // Extract filename from the URL
        const filename = url.substring(url.lastIndexOf('/') + 1);

        const link = document.createElement('a');
        link.href = data.file;
        link.download = filename;
        link.target = '_blank';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };

    const { data, isError, isLoading, isSuccess } = useQuery(
        ['blog-details'],
        () => {
            return getObject('blog', id);
        }
    );

    if (isError) {
        return <Typography>Occurred an Error</Typography>;
    }

    if (isLoading) {
        return <BlogDetailsLoading />;
    }

    if (isSuccess) {
        return (
            <Box
                sx={{
                    minHeight: '1000px',
                    backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                }}
            >
                <Helmet>
                    <title>{data.title}</title>
                    <meta name="description" content={data.description} />
                    <meta property="og:title" content={data.title} />
                    <meta property="og:image" content={data?.images[0].image} />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Container>
                    <Box>
                        <Button
                            onClick={() => back()}
                            sx={{ mt: 10, mb: 10 }}
                            startIcon={<KeyboardBackspaceIcon sx={{ mr: 1 }} />}
                        >
                            <Typography>Back</Typography>
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: 5,
                        }}
                    >
                        <Box
                            sx={{
                                width: {
                                    xl: '70%',
                                    lg: '70%',
                                    md: '80%',
                                    sm: '90%',
                                    xs: '90%',
                                },
                            }}
                        >
                            <Typography
                                variant="h4"
                                fontWeight={'bold'}
                                sx={{
                                    fontSize: {
                                        xl: '30px',
                                        lg: '30px',
                                        md: '25px',
                                        sm: '20px',
                                        xs: '19px',
                                    },
                                }}
                            >
                                {data.title}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography variant="body2" mt={2}>
                                    {data.date}
                                </Typography>

                                <IconButton onClick={() => handleShare(data)}>
                                    <ShareIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box
                            component={'img'}
                            src={data.images[0].image}
                            alt={
                                data.images[0].image &&
                                data.images[0].image.substring(
                                    data.images[0].image.lastIndexOf('/') + 1
                                )
                            }
                            sx={{
                                width: '100%',
                                height: {
                                    xl: '500px',
                                    lg: '500px',
                                    md: '400px',
                                    sm: '300px',
                                    xs: '200px',
                                },
                            }}
                        />
                        <Box
                            sx={{
                                width: {
                                    xl: '70%',
                                    lg: '70%',
                                    md: '80%',
                                    sm: '90%',
                                    xs: '90%',
                                },
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data.description,
                                }}
                            />
                        </Box>
                        <Box>
                            {data.file && (
                                <Button
                                    onClick={handleDownload}
                                    startIcon={
                                        <PictureAsPdfIcon
                                            fontSize="large"
                                            sx={{ color: 'red' }}
                                        />
                                    }
                                >
                                    Download{' '}
                                    {data.file &&
                                        data.file.substring(
                                            data.file.lastIndexOf('/') + 1
                                        )}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    }
};

export default BlogDetails;
