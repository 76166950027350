import { Box, Button, Paper, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';

const OurTeamCardDesign = (props) => {
    const { value, setValue } = useAppContext();
    const data = props.data;
    return (
        <Paper
            sx={{
                width: {
                    xl: '320px',
                    lg: '320px',
                    md: '320px',
                    sm: '320px',
                    xs: '100%',
                },
                height: '420px',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '20px',
            }}
        >
            <Box
                sx={{
                    width: {
                        xl: '320px',
                        lg: '320px',
                        md: '320px',
                        sm: '320px',
                        xs: '100%',
                    },
                    height: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: Colors.PRIMARY,
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                    borderBottomLeftRadius: '30px',
                }}
            >
                <Box
                    component={'img'}
                    src={data.image}
                    alt={
                        data.image &&
                        data.image.substring(data.image.lastIndexOf('/') + 1)
                    }
                    sx={{
                        width: '120px',
                        height: '120px',
                        borderRadius: '50%',
                        border: '3px solid white',
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '40%',
                    mt: 2,
                }}
            >
                <Box>
                    <Typography fontWeight={'bold'}>{data.name}</Typography>
                </Box>
                <Box>
                    <Typography fontWeight={'bold'} variant="body2">
                        {data.position}
                    </Typography>
                </Box>
                <Box sx={{ width: '96%' }}>
                    <Typography textAlign={'center'}>
                        {data.description}
                    </Typography>
                </Box>
                <Box>
                    <Link to={'/our-team/'} style={{ textDecoration: 'none' }}>
                        <Button
                            onClick={() => setValue('team')}
                            variant="contained"
                            sx={{ borderRadius: '10px' }}
                        >
                            View More
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Paper>
    );
};

export default OurTeamCardDesign;
